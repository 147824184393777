export const about = 'about';
export const membership = 'membership';
export const our_hotels = 'our_hotels';
export const our_hot_picks = 'our_hot_picks';
export const join_us = 'join_us';
export const sign_in = 'sign_in';
export const email = 'email';
export const password = 'password';
export const forgot_password = 'forgot_password';
export const not_a_member = 'not_a_member';
export const show = 'show';
export const hide = 'hide';
export const keep_me_signed_in = 'keep_me_signed_in';
export const email_input_error_warning = 'email_input_error_warning';
export const password_input_error_warning = 'password_input_error_warning';
export const coupon_input_error_warning = 'coupon_input_error_warning';
export const sign_in_error = 'sign_in_error';
export const continue_key = 'continue';
export const back_to_sign_in = 'back_to_sign_in';
export const check_your_email = 'check_your_email';
export const check_your_email_description = 'check_your_email_description';
export const contact_us = 'contact_us';
export const our_company = 'our_company';
export const become_a_member = 'become_a_member';
export const the_team = 'the_team';
export const careers = 'careers';
export const faq = 'faq';
export const terms_and_conditions = 'terms_and_conditions';
export const as_a_registered_member = 'as_a_registered_member';
export const privacy_policy = 'privacy_policy';
export const join_the_club = 'join_the_club';
export const leisure = 'leisure';
export const corporate = 'corporate';
export const annual_fee_individual_membership =
  'annual_fee_individual_membership';
export const annual_fee_group_membership = 'annual_fee_group_membership';
export const currency = 'currency';
export const per_year = 'per_year';
export const download_the_app = 'download_the_app';
export const frequently_asked_questions = 'frequently_asked_questions';
export const cant_find = 'cant_find';
export const cant_find_answer = 'cant_find_answer';
export const your_details = 'your_details';
export const name_is_required = 'name_is_required';
export const name = 'name';
export const email_address_required = 'email_address_required';
export const phone_number_required = 'phone_number_required';
export const first_name = 'first_name';
export const last_name = 'last_name';
export const confirm_password = 'confirm_password';
export const cityOfResidence_input_error = 'cityOfResidence_input_error';
export const cityOfResidence_id_input_error = 'cityOfResidence_id_input_error';
export const phone_number = 'phone_number';
export const country_code = 'country_code';
export const birthday = 'birthday';
export const instagram_username = 'instagram_username';
export const referral_code = 'referral_code';
export const optional = 'optional';
export const whats_app_consent_message = 'whats_app_consent_message';
export const terms_and_conditions_consent_message =
  'terms_and_conditions_consent_message';
export const fullName_input_error_warning = 'fullName_input_error_warning';
export const child_name_input_error_warning = 'child_name_input_error_warning';
export const child_name_max_age_error_warning =
  'child_name_max_age_error_warning';
export const radio_input_error_warning = 'radio_input_error_warning';
export const instagram_username_input_error_warning =
  'instagram_username_input_error_warning';
export const credit_card_name_input_error_warning =
  'credit_card_name_input_error_warning';
export const credit_card_number_input_error_warning =
  'credit_card_number_input_error_warning';
export const credit_card_expiry_date_input_error_warning =
  'credit_card_expiry_date_input_error_warning';
export const credit_card_expiry_month_input_error_warning =
  'credit_card_expiry_month_input_error_warning';
export const credit_card_expiry_year_input_error_warning =
  'credit_card_expiry_year_input_error_warning';
export const credit_card_cvc_input_error_warning =
  'credit_card_cvc_input_error_warning';
export const firstName_input_error_warning = 'firstName_input_error_warning';
export const lastName_input_error_warning = 'lastName_input_error_warning';
export const confirmPassword_input_error_warning =
  'confirmPassword_input_error_warning';
export const phoneNumber_input_error_warning =
  'phoneNumber_input_error_warning';
export const dateOfBirth_input_error_warning =
  'dateOfBirth_input_error_warning';
export const termsAccept_input_error_warning =
  'termsAccept_input_error_warning';
export const terms_and_conditions_dialogue_text_1 =
  'terms_and_conditions_dialogue_text_1';
export const terms_and_conditions_dialogue_text_2 =
  'terms_and_conditions_dialogue_text_2';
export const terms_and_conditions_dialogue_text_3 =
  'terms_and_conditions_dialogue_text_3';
export const terms_and_conditions_dialogue_text_4 =
  'terms_and_conditions_dialogue_text_4';
export const join_now = 'join_now';
export const enter_your_payment_details = 'enter_your_payment_details';
export const total = 'total';
export const one_year_membership = 'one_year_membership';
export const what_our_members_say_about_us = 'what_our_members_say_about_us';
export const view_membeships = 'view_membeships';
export const we_guarantee_the_lowest_rates = 'we_guarantee_the_lowest_rates';
export const find_your_next_luxury = 'find_your_next_luxury';
export const explore_hotels = 'explore_hotels';
export const explore_our_destinations = 'explore_our_destinations';
export const join_our_newsletter = 'join_our_newsletter';
export const newsletter_modal_description = 'newsletter_modal_description';
export const subscribe = 'subscribe';
export const thank_you = 'thank_you';
export const thank_you_newsletter_title = 'thank_you_newsletter_title';
export const thank_you_newsletter_description =
  'thank_you_newsletter_description';
export const why_little_emperors = 'why_little_emperors';
export const why_little_emperors_description =
  'why_little_emperors_description';
export const access_the_best_prices = 'access_the_best_prices';
export const access_the_best_prices_content = 'access_the_best_prices_content';
export const enjoy_complimentry_benefits = 'enjoy_complimentry_benefits';
export const enjoy_complimentry_benefits_content =
  'enjoy_complimentry_benefits_content';
export const relax_and_switch_off = 'relax_and_switch_off';
export const relax_and_switch_off_content = 'relax_and_switch_off_content';
export const our_hotels_section_description = 'our_hotels_section_description';
export const what_the_press_say_about_us = 'what_the_press_say_about_us';
export const your_partner_details = 'your_partner_details';
export const partner_first_name = 'partner_first_name';
export const partner_last_name = 'partner_last_name';
export const cardholder_name = 'cardholder_name';
export const card_number = 'card_number';
export const expiry_date = 'expiry_date';
export const partners_section_title = 'partners_section_title';
export const meet_our_team = 'meet_our_team';
export const instagram_section_title = 'instagram_section_title';
export const instagram_section_description = 'instagram_section_description';
export const instagram = 'instagram';
export const articles = 'articles';
export const load_more = 'load_more';
export const welcome_to_little_emperors = 'welcome_to_little_emperors';
export const congratulations = 'congratulations';
export const registration_success_description =
  'registration_success_description';
export const compare_membership = 'compare_membership';
export const compare_membership_description = 'compare_membership_description';
export const compare_membership_corporate = 'compare_membership_corporate';
export const what_the_press_say = 'what_the_press_say';
export const more_articles = 'more_articles';
export const press = 'press';
export const password_reset = 'password_reset';
export const new_key = 'new';
export const again = 'again';
export const reset = 'reset';
export const why_join = 'why_join';
export const preferred_rates = 'preferred_rates';
export const preferred_rates_content = 'preferred_rates_content';
export const enjoy_complimentry = 'enjoy_complimentry';
export const enjoy_complimentry_content = 'enjoy_complimentry_content';
export const relax_and_switch_off_membership_page =
  'relax_and_switch_off_membership_page';
export const membership_page_content = 'membership_page_content';
export const the_role = 'the_role';
export const what_you_need = 'what_you_need';
export const job_position_instruction = 'job_position_instruction';
export const submit = 'submit';
export const any_additional_comments = 'any_additional_comments';
export const apply = 'apply';
export const job_application_sent_success_message =
  'job_application_sent_success_message';
export const experienced_travel_agent = 'experienced_travel_agent';
export const new_travel_agent = 'new_travel_agent';
export const lowest_price_title = 'lowest_price_title';
export const lowest_price_description = 'lowest_price_description';
export const book_with_benefits_title = 'book_with_benefits_title';
export const book_with_benefits_description = 'book_with_benefits_description';
export const individual = 'individual';
export const multi_user = 'multi_user';
export const search_hotels = 'search_hotels';
export const inspiration = 'inspiration';
export const view_all = 'view_all';
export const results_found = 'results_found';
export const benefits_title = 'benefits_title';
export const view_hotel = 'view_hotel';
export const to_start_your_free_trial = 'to_start_your_free_trial';
export const company_name = 'company_name';
export const enquiry_form_city_of_residennce =
  'enquiry_form_city_of_residennce';
export const enquiry_form_travel_professional_question =
  'enquiry_form_travel_professional_question';
export const how_did_you_hear_about_myler = 'how_did_you_hear_about_myler';
export const enquire = 'enquire';
export const myler_enquiry_form_name_required = 'myler_enquiry_form_name_required';
export const myler_enquiry_form_valid_email = 'myler_enquiry_form_valid_email';
export const iata_number_question = 'iata_number_question';
export const partner_consortium_question = 'Are you part of any preferred hotel partner program or consortia?';

